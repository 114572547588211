import { useState } from 'react'
import Select from 'react-select'

const InputSelect = ({ options, inputId, height = 64, borderRadius = 12 }) => {
    const [selectedOption, setSelectedOption] = useState(null)

    return (
        <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
            placeholder={'Select'}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary: '#7000ff',
                },
            })}
            inputId={inputId}
            styles={{
                control: (base) => ({
                    ...base,
                    height: `${height}px`,
                    borderRadius: `${borderRadius}px`,
                    borderColor: 'rgb(24, 0, 54)',
                    width: '100%',
                    maxWidth: '350px',
                    fontSize: '18px',
                    fontWeight: '300',
                    cursor: 'pointer',
                }),
                valueContainer: (base) => ({
                    ...base,
                    marginLeft: '6px',
                }),
                placeholder: (base) => ({
                    ...base,
                    color: '#0000004d',
                    fontSize: '18px',
                    fontWeight: '300',
                }),
                menu: (base) => ({
                    ...base,
                    maxWidth: '350px',
                    fontSize: '18px',
                    fontWeight: '300',
                    borderRadius: '10px',
                    overflow: 'hidden',
                }),
            }}
        />
    )
}

export default InputSelect

import { Button, Input, SearchCard } from '../../components'
import styles from './styles.module.css'

const SearchSection = () => {
    return (
        <div className={styles.wrapper}>
            <SearchCard />
            <div className={styles.searchContainer}>
                <Input placeholder="Search..." />
                <Button
                    className={`${styles.button} max-w-[30%] sm:max-w-full`}
                    text="Search"
                    search
                />
            </div>
        </div>
    )
}

export default SearchSection

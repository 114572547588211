import { Container } from '../../components'
import {
    Reach,
    HomeSection,
    SocialMediaLine,
    HowItWorksSection,
    PlatformBenefitsSection,
    StartedNow,
    Footer,
    BloggingSection,
    WhoIsSection,
} from '../../sections'

import { homeItems } from '../../constants/home'

const BloggersPage = () => {
    return (
        <>
            <Container>
                <BloggingSection />
                <Reach />
            </Container>
            <HomeSection item={homeItems.brands} brands />
            <SocialMediaLine />
            <Container>
                <WhoIsSection />
                <HowItWorksSection />
                <PlatformBenefitsSection />
            </Container>
            <HomeSection item={homeItems.library} library />
            <StartedNow />
            <Footer />
        </>
    )
}

export default BloggersPage

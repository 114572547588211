import logoWhite from '../../assets/logo_white.svg'
import logoColorful from '../../assets/logo.svg'

import styles from './styles.module.css'

const Logo = ({ isWhite = true, className }) => {
    return (
        <img
            className={`${styles.logo} ${className}`}
            src={isWhite ? logoWhite : logoColorful}
            alt="Logo"
        />
    )
}

export default Logo

import { LinkButton, Container, TalentItems, Title } from '../../components'
import styles from './styles.module.css'

const HomeSection = ({
    item,
    introduction,
    bloggers,
    giveaway,
    brands,
    library,
    talent,
    advertisers,
    tips,
}) => {
    return (
        <Container>
            <div
                id={giveaway ? 'giveaway' : null}
                className={`${styles.wrapper} bg-${item.backgroundColor} text-${
                    item.textColor
                } ${introduction ? 'mb-52 lg:mb-16' : ''} ${
                    giveaway || bloggers || brands || library
                        ? 'flex-row-reverse lg:flex-col-reverse'
                        : ''
                } ${brands ? 'mb-0 lg:pb-0 lg:mb-16' : ''}  ${
                    giveaway || tips || library ? 'py-0' : ''
                } ${talent ? 'mb-14' : ''}`}
            >
                <div className={`${styles.textColumn} gap-y-5`}>
                    <Title
                        text={item.title}
                        bigTitle={introduction || talent}
                    />
                    <ul className={styles.text}>
                        {item.textList.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                    </ul>
                    {talent && <TalentItems />}
                    <LinkButton
                        to={item.linkTo}
                        text={item.buttonText}
                        className={`${styles.button} bg-${item.buttonColor} text-${item.textButtonColor}`}
                    />
                </div>
                <img
                    className={`${styles.image} ${
                        introduction
                            ? 'w-[50%] lg:w-[60%] md:w-[70%] sm:w-[90%]'
                            : ''
                    } ${tips ? 'w-[30%] md:w-[40%] sm:w-[50%]' : ''} ${
                        bloggers || advertisers
                            ? 'w-[30%] lg:w-[40%] md:w-[45%] sm:w-[50%]'
                            : ''
                    } ${
                        talent || brands
                            ? 'w-[38%] lg:w-[50%] md:w-[60%] sm:w-[80%]'
                            : ''
                    }`}
                    src={item.image}
                    alt="phone"
                />
            </div>
        </Container>
    )
}

export default HomeSection

import styles from './styles.module.css'

const Button = ({ text, className, search }) => {
    return (
        <button className={`${styles.button} ${className}`}>
            {text} {search && <span className={styles.searchImage}></span>}
        </button>
    )
}

export default Button

import { Logo, Navigation, Container } from '../../components'

const Footer = () => {
    return (
        <footer className="mx-auto bg-purple py-3">
            <Container>
                <div className="flex items-center justify-between">
                    <Logo className="w-16" />
                    <Navigation
                        color="white"
                        className="text-white text-right"
                    />
                </div>
            </Container>
        </footer>
    )
}

export default Footer

import { Title, LinkButton } from '../../components'
import blogImage from '../../assets/blog.webp'
import styles from './styles.module.css'

const BloggingSection = () => {
    return (
        <div className={styles.wrapper}>
            <div>
                <div className={styles.textColumn}>
                    <Title text="Blogging is a serious profession!" bigTitle />
                    <h4 className={styles.subtitle}>
                        It is important to receive stable advertising orders
                    </h4>
                    <p className={styles.text}>
                        Thanks to our platform, thousands of advertisers who are
                        ready to place their ads will find out about you
                    </p>
                </div>
                <LinkButton
                    to="/publication"
                    text="Add project"
                    className={styles.button}
                />
            </div>
            <img className={styles.image} src={blogImage} alt="phone" />
        </div>
    )
}

export default BloggingSection

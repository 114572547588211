import { LinkButton } from '../../components'
import image from '../../assets/clip.svg'
import styles from './styles.module.css'

const File = () => {
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <LinkButton
                    to="/publication"
                    text="Attach a file"
                    className={styles.button}
                    color="text-purple"
                />
                <div className={styles.drop}>
                    <img className="mr-5" src={image} alt="clip" />
                    <div>Or drag and drop files into this area</div>
                </div>
            </div>
            <div className={styles.text}>
                Upload terms of reference, advertising examples, photos or other
                documents.
            </div>
        </div>
    )
}

export default File

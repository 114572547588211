import { Container } from '../../components'
import {
    CategoriesAndTagsSection,
    ProjectBudgetSection,
    ProjectPublicationSection,
} from '../../sections'

const PublicationPage = () => {
    return (
        <Container>
            <div className="mt-16 px-8 md:px-2">
                <form>
                    <ProjectPublicationSection />
                    <CategoriesAndTagsSection />
                    <ProjectBudgetSection />
                </form>
            </div>
        </Container>
    )
}

export default PublicationPage

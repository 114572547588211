import { NavLink, useNavigate } from 'react-router-dom'
import { scroller } from 'react-scroll'
import { navItems } from '../../constants'

import styles from './styles.module.css'

const Navigation = ({ color, closeMenu, className, listClassName }) => {
    const navigate = useNavigate()

    const goToHomeAndScroll = async (anchor) => {
        await navigate('/')
        await scroller.scrollTo(anchor, {
            duration: 1000,
            delay: 100,
            smooth: true,
        })
    }

    return (
        <nav className={className}>
            <ul className={`lg:flex-col ${styles.list} ${listClassName}`}>
                {navItems.map(({ name, link }) => (
                    <li key={name} onClick={closeMenu}>
                        {name === 'Giveaway' ? (
                            <a
                                className={styles.link}
                                onClick={() => goToHomeAndScroll('giveaway')}
                            >
                                Giveaway
                            </a>
                        ) : (
                            <NavLink
                                className={
                                    color === 'white'
                                        ? ({ isActive }) =>
                                              isActive
                                                  ? `${styles.link} font-semibold text-white`
                                                  : `${styles.link} text-white}`
                                        : ({ isActive }) =>
                                              isActive
                                                  ? `${styles.link} font-semibold text-pink`
                                                  : `${styles.link} `
                                }
                                to={link}
                            >
                                {name}
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Navigation

import styles from './styles.module.css'

const Input = ({ type = 'text', placeholder, id, min, className }) => {
    return (
        <input
            className={`${styles.input} ${className}`}
            placeholder={placeholder}
            type={type}
            id={id}
            min={min}
        />
    )
}

export default Input

import image1 from '../../assets/stingray/stingray-1.webp'
import image2 from '../../assets/stingray/stingray-2.webp'
import image3 from '../../assets/stingray/stingray-3.webp'
import image4 from '../../assets/giveaway.webp'
import image5 from '../../assets/stingray/stingray-4.webp'
import image6 from '../../assets/brands.webp'
import image7 from '../../assets/stingray/stingray-6.webp'
import image8 from '../../assets/stingray/stingray-5.webp'

export const images = [image1, image2, image3, image4, image5]

export const homeItems = {
    upMee: {
        title: 'UpMee - international advertising exchange',
        textList: [
            'Millions of bloggers from different countries ready to advertise your product and make it popular.',
            'Work without risks, save money and time.',
        ],
        buttonText: 'Add project',
        image: image1,
        backgroundColor: 'white',
        textColor: 'black',
        buttonColor: 'purple',
        linkTo: '/publication',
        textButtonColor: 'white',
    },
    bloggers: {
        title: 'Benefits for bloggers',
        textList: [
            'The best brands from all over the world are waiting to cooperate with you.',
            'Local advertisers and businessmen need you.',
            'Verified customers.',
            'Built-in calendar in the platform.',
            'Statistics, ratings and defense.',
            'Legal work and stable payments.',
            'Accounting and Documents.',
            'One link system.',
            'Additional creative tools.',
        ],
        buttonText: 'Open',
        image: image2,
        backgroundColor: 'purple',
        textColor: 'white',
        buttonColor: 'white',
        linkTo: '/bloggers',
        textButtonColor: 'purple',
    },
    advertisers: {
        title: 'For advertisers',
        textList: [
            'Drive targeted actions with our platform and make your business or product popular.',
            "Search engine advertising is outdated! Don't waste your budget on Google ADS.",
            'Our platform is easy to use for you or your advertising managers.',
            'Your money is safe until the blogger completes the project.',
            'Buying ads in one click from several bloggers at the same time.',
            'Accurate independent advertising campaign statistics.',
            'Accounting and documents for legal entities. Real ratings and reviews.',
        ],
        buttonText: 'Open',
        image: image3,
        backgroundColor: 'black',
        textColor: 'white',
        buttonColor: 'white',
        linkTo: '/advertisers',
        textButtonColor: 'black',
    },
    giveaway: {
        title: 'Giveaway',
        textList: [
            'The best free tool to run independent sweepstakes.',
            'No restrictions list of all giveaway events in the world.',
            'History of results and evidence of awards for participants.',
            'Require bloggers to run fair sweepstakes with automatic lists of participants.',
        ],
        buttonText: 'Open',
        image: image4,
        backgroundColor: 'white',
        textColor: 'black',
        buttonColor: 'purple',
        linkTo: '/',
        textButtonColor: 'white',
    },
    tips: {
        title: 'Tips',
        textList: [
            'This is a paid content subscription service that allows bloggers and authors of unique content to additionally monetize their creativity and improve their work with subscribers.',
            'Exclusive photos, Uncensored videos, Courses and video lessons, educational materials and much more.',
            'You can set several levels: minimum, medium and maximum. You can also sell each post separately for a price.',
        ],
        buttonText: 'Open',
        image: image5,
        backgroundColor: 'white',
        textColor: 'black',
        buttonColor: 'purple',
        linkTo: '/tips',
        textButtonColor: 'white',
    },
    brands: {
        title: 'We are trusted by the best brands',
        textList: [
            "Over 300 of Europe's leading advertisers are already working on our UpMee platform",
        ],
        buttonText: 'Add project',
        image: image6,
        backgroundColor: 'white',
        textColor: 'black',
        buttonColor: 'purple',
        linkTo: '/publication',
        textButtonColor: 'white',
    },
    library: {
        title: 'Library of Knowledge',
        textList: [
            'Oops, this section with useful information and instructions for bloggers, influencers and webmasters is available only after registration. Hurry up, register and verify your social networks to start monetizing your creativity and your traffic',
        ],
        buttonText: 'Registration',
        image: image7,
        backgroundColor: 'white',
        textColor: 'black',
        buttonColor: 'purple',
        linkTo: '/sign_up',
        textButtonColor: 'white',
    },
    talent: {
        title: 'Your talent costs money',
        textList: [
            'The best way for content creators to make money and connect with the audience',
        ],
        buttonText: 'Create author page',
        image: image8,
        backgroundColor: 'white',
        textColor: 'black',
        buttonColor: 'purple',
        linkTo: '/sign_up',
        textButtonColor: 'white',
    },
}

import { BloggerLink } from '../'
import styles from './styles.module.css'
import { useState } from 'react'

const BloggerCard = ({ blogger }) => {
    const [isLike, setIsLike] = useState(false)

    return (
        <li className={styles.card}>
            <img
                className={styles.image}
                src={blogger.image}
                alt={blogger.name}
            />
            <div className={styles.textColumn}>
                <div>
                    <ul className={styles.linkList}>
                        {Object.entries(blogger.links).map((link, index) => (
                            <BloggerLink key={index} link={link} />
                        ))}
                    </ul>
                </div>
                <h6 className={styles.title}>{blogger.name}</h6>
                <div>
                    <div>Uploads: {blogger.uploads}</div>
                    <div>Subscribers: {blogger.subscribers}</div>
                    <div>Video views: {blogger.videoViews}</div>
                </div>
            </div>
            <div className={styles.buttons}>
                <a
                    className={styles.linkButton}
                    href={blogger.links.youtube}
                    target="_blank"
                    rel="noreferrer"
                >
                    Open
                </a>
                <button
                    className={`${styles.button} ${
                        isLike ? styles.active : ''
                    }`}
                    onClick={() => setIsLike(!isLike)}
                >
                    Add to
                    <span className={styles.like} />
                </button>
            </div>
        </li>
    )
}

export default BloggerCard

import { Button, Input, InputSelect } from '../../components'
import {
    countriesOptions,
    socialOptions,
    typeOptions,
    categoriesOptions,
    subscribersOptions,
    genderOptions,
    viewsOptions,
    advertisementOptions,
    ageOptions,
} from '../../constants/options'
import styles from './styles.module.css'

const FilterSection = () => {
    return (
        <div className={styles.wrapper}>
            <h3 className={styles.title}>Search filters</h3>
            <div className={styles.content}>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="country">
                        Country
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="country"
                        options={countriesOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="city">
                        City
                    </label>
                    <Input
                        className="rounded-8 h-3"
                        id="city"
                        placeholder="Enter city"
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="social">
                        Social Network
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="social"
                        options={socialOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="type">
                        Type
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="type"
                        options={typeOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="categories">
                        Categories
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="categories"
                        options={categoriesOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="subscribers">
                        Subscribers
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="subscribers"
                        options={subscribersOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="age">
                        Age
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="age"
                        options={ageOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="gender">
                        Gender
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="gender"
                        options={genderOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="views">
                        Views
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="views"
                        options={viewsOptions}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="price">
                        Price
                    </label>
                    <Input
                        className="rounded-8 h-3 px-3"
                        id="price"
                        type="number"
                        placeholder="Enter price"
                        min={1}
                    />
                </div>
                <div className={styles.searchItem}>
                    <label className={styles.label} htmlFor="advertisement">
                        Type of advertisement
                    </label>
                    <InputSelect
                        height={42}
                        borderRadius={8}
                        inputId="advertisement"
                        options={advertisementOptions}
                    />
                </div>
            </div>
            <div className={styles.buttons}>
                <Button className={styles.resetButton} text="Reset filter" />
                <Button className={styles.searchButton} text="Search" search />
            </div>
        </div>
    )
}

export default FilterSection

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import {
    HomePage,
    SignUpPage,
    SignInPage,
    PublicationPage,
    Page404,
    TipsPage,
    AdvertisersPage,
    BloggersPage,
    ExchangePage,
} from '../../pages'
import { Header } from '../../sections'
import { ScrollToTop } from '../../components'

const App = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/bloggers" element={<BloggersPage />} />
                <Route path="/exchange" element={<ExchangePage />} />
                <Route path="/advertisers" element={<AdvertisersPage />} />
                <Route path="/tips" element={<TipsPage />} />
                <Route path="/sign_in" element={<SignInPage />} />
                <Route path="/sign_up" element={<SignUpPage />} />
                <Route path="/publication" element={<PublicationPage />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App

import styles from './styles.module.css'

const Burger = ({ isOpen, handleIsOpen }) => {
    return (
        <div
            className={`${styles.burger} ${isOpen ? 'rotate-90' : ''}`}
            onClick={handleIsOpen}
        >
            <span className={styles.shortLine}></span>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
        </div>
    )
}

export default Burger

import { Container, Steps, Title } from '../../components'
import styles from './styles.module.css'

const PlatformWork = () => {
    return (
        <Container maxWidth="max-w-7xl">
            <div className={styles.container}>
                <Title text="How does our platform work?" titleMargin />
                <Steps />
                <div className={styles.info}>
                    <div className={styles.point} />
                    <div className={styles.message}>
                        3. Your money is protected on our site. The blogger will
                        receive a reward after he completes the work. If the
                        influencer has not met the conditions, we will refund
                        your money.
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default PlatformWork

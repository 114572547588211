import styles from './styles.module.css'

const BloggerLink = ({ link }) => {
    const getColor = (color) => {
        switch (color) {
            case 'youtube':
                return 'bg-youtube'
            case 'tiktok':
                return 'bg-tiktok'
            case 'twitter':
                return 'bg-twitter'
            case 'facebook':
                return 'bg-facebook'
            case 'instagram':
                return 'bg-instagram'
        }
    }

    return (
        <li>
            <a
                className={`${styles.link} ${getColor(link[0])}`}
                href={link[1]}
                target="_blank"
                rel="noreferrer"
            >
                #{link[0]}
            </a>
        </li>
    )
}

export default BloggerLink

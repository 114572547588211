import step1 from '../assets/icons/icon_paper-plane.svg'
import step2 from '../assets/icons/icon_celebrity.svg'
import step3 from '../assets/icons/icon_money.svg'
import step4 from '../assets/icons/icon_todo.svg'
import howToImage1 from '../assets/icons/icon_key.svg'
import howToImage2 from '../assets/icons/icon_diamond.svg'
import howToImage3 from '../assets/icons/icon_player.svg'
import howToImage4 from '../assets/icons/icon_hands.svg'
import howToImage5 from '../assets/icons/icon_microphone.svg'
import howToImage6 from '../assets/icons/icon_crown.svg'
import howToImage7 from '../assets/icons/icon_t-shirt.svg'
import howToImage8 from '../assets/icons/icon_album.svg'
import howToImage9 from '../assets/icons/icon_message.svg'
import howToImage10 from '../assets/icons/icon_answer.svg'
import whyImage1 from '../assets/icons/icon_shield.svg'
import whyImage2 from '../assets/icons/icon_coin.svg'
import whyImage3 from '../assets/icons/icon_edit-tools.svg'
import whyImage4 from '../assets/icons/icon_customer-care.svg'
import platformBenefitsImage1 from '../assets/icons/icon_shopping_list.svg'
import platformBenefitsImage2 from '../assets/icons/icon_coin.svg'
import platformBenefitsImage3 from '../assets/icons/icon_cart.svg'
import platformBenefitsImage4 from '../assets/icons/icon_shield.svg'
import platformBenefitsImage5 from '../assets/icons/icon_target-star.svg'
import platformBenefitsImage6 from '../assets/icons/icon_target.svg'
import whoIsImage1 from '../assets/icons/icon_blogger.svg'
import whoIsImage2 from '../assets/icons/icon_chat.svg'
import whoIsImage3 from '../assets/icons/icon_web.svg'
import whoIsImage4 from '../assets/icons/icon_influencer.svg'
import whoIsImage5 from '../assets/icons/icon_website.svg'
import howItWorksImage1 from '../assets/how_it_work/step_1.webp'
import howItWorksImage2 from '../assets/how_it_work/step_2.webp'
import howItWorksImage3 from '../assets/how_it_work/step_3.webp'
import talentImage1 from '../assets/icons/icon_star.svg'
import talentImage2 from '../assets/icons/icon_player.svg'
import talentImage3 from '../assets/icons/icon_coin.svg'
import talentImage4 from '../assets/icons/icon_key.svg'
import talentImage5 from '../assets/icons/icon_rank.svg'
import talentImage6 from '../assets/icons/icon_padlock.svg'

export const howToImages = [
    howToImage1,
    howToImage2,
    howToImage3,
    howToImage4,
    howToImage5,
    howToImage6,
    howToImage7,
    howToImage8,
    howToImage9,
    howToImage10,
]

export const navItems = [
    {
        name: 'How it works',
        link: '/',
    },
    {
        name: 'For Bloggers',
        link: '/bloggers',
    },
    {
        name: 'For Advertisers',
        link: '/advertisers',
    },
    {
        name: 'Exchange of bloggers',
        link: '/exchange',
    },
    {
        name: 'Giveaway',
        link: '/',
    },
    {
        name: 'Tips',
        link: '/tips',
    },
]

export const stepImages = [step1, step2, step3, step4]

export const stepTexts = [
    'An advertiser publishes their project on the UpMee platform for free to find a blogger or influencer.',
    'The advertiser chooses suitable free bloggers. Advertising can be given to many bloggers at the same time.',
    'Determine the price of an advertising publication from the artist. Make a payment to us on the platform. Then the blogger will start working on your assignment.',
    'Check the work and complete the project. Rate the artist.',
]

export const socialMediaList = [
    '#YOUTUBE',
    '#TIKTOK',
    '#INSTAGRAM',
    '#TELEGRAM',
    '#TWITTER',
    '#SNAPCHAT',
    '#TWITCH',
    '#WECHAT',
    '#SINA WEIBO',
    '#QQ',
    '#KUAISHOU',
    '#QZONE',
    '#QUORA',
    '#DISCORD',
    '#MASTODON',
]

export const howToItems = [
    {
        name: 'VIDEO BLOGGER',
        slide: [
            {
                image: howToImage1,
                text: 'Early access to videos',
            },
            {
                image: howToImage2,
                text: 'Exclusive videos',
            },
            {
                image: howToImage3,
                text: 'Unpublished moments',
            },
        ],
    },
    {
        name: 'PODCAST',
        slide: [
            {
                image: howToImage1,
                text: 'Early access to podcast',
            },
            {
                image: howToImage4,
                text: 'Personal thanks in the new release',
            },
            {
                image: howToImage5,
                text: 'Subscriber participation in your podcast',
            },
        ],
    },
    {
        name: 'ILLUSTRATOR',
        slide: [
            {
                image: howToImage3,
                text: 'Video of the creation process step by step operation',
            },
            {
                image: howToImage6,
                text: 'Getting content in HQ',
            },
            {
                image: howToImage7,
                text: 'Clothing with your illustrations',
            },
        ],
    },
    {
        name: 'MUSIC',
        slide: [
            {
                image: howToImage1,
                text: 'Early access to new music',
            },
            {
                image: howToImage8,
                text: 'Voting for album / single cover',
            },
            {
                image: howToImage4,
                text: 'Personal thanks in video format',
            },
        ],
    },
    {
        name: 'JOURNALIST',
        slide: [
            {
                image: howToImage1,
                text: 'Early access to upcoming posts',
            },
            {
                image: howToImage9,
                text: 'The ability to propose a topic for a new post',
            },
            {
                image: howToImage10,
                text: 'The ability to get answers to your questions on the material',
            },
        ],
    },
]

export const whyUsItems = [
    {
        image: whyImage1,
        text: 'Worldwide secure payments',
    },
    {
        image: whyImage2,
        text: 'The minimum fee. Subscription Model',
    },
    {
        image: whyImage3,
        text: 'You are the only one who sets prices and decides what to offer subscribers',
    },
    {
        image: whyImage4,
        text: 'We take care of all technical issues. Protection and support 24/7',
    },
]

export const reachItems = [
    { text: 'promotions', value: '1 802' },
    { text: 'targeted actions', value: '3 531 718' },
    { text: 'bloggers', value: '70 227' },
]

export const howItWorksItems = [
    {
        title: 'Negotiation of the terms of the promotion',
        text: 'We agree on the terms of a special offer that is not publicly available. It can be a discount or a gift for a purchase.',
    },
    {
        title: 'Determination of the amount of remuneration',
        text: 'We approve the amount of a fixed or percentage remuneration for bloggers for each targeted action.',
    },
    {
        title: 'Sending promo codes or promotional links',
        text: 'The partner sends a list of unique promotional codes or personal links, which, when used, gives the buyer access to the agreed promotion.',
    },
    {
        title: 'Assigning a promotional code to bloggers',
        text: 'Bloggers who choose your promotion from the list of offers are automatically assigned a personal promo code or link.',
    },
    {
        title: 'Transfer of data on target actions',
        text: 'The partner records actions using the transferred codes or links in its database and on a weekly basis, during the entire period of the promotion, sends a detailed report on the conversions of each code or link.',
    },
    {
        title: 'Summing up the action',
        text: 'The actual budget of commission payments to bloggers directly depends on the results achieved as a result of the campaign and cannot be limited from above',
    },
]

export const platformBenefitsItems = [
    {
        image: platformBenefitsImage1,
        text: 'Working with bloggers is automated and does not require discussing the terms of cooperation with each of them',
    },
    {
        image: platformBenefitsImage2,
        text: 'The capabilities of the platform allow you to attract customers from social networks both to the partner’s online store and offline points of sale',
    },
    {
        image: platformBenefitsImage3,
        text: 'Offers are sent to relevant bloggers targeted by city, gender, and the interests of their followers',
    },
    {
        image: platformBenefitsImage4,
        text: 'The platform is integrated with several ORDs at once, all advertising is placed in full compliance with the Federal Law «On Advertising»',
    },
    {
        image: platformBenefitsImage5,
        text: 'Each blogger undergoes an automated audience and content quality check. Bloggers with low-quality or prohibited content are not allowed on the platform',
    },
    {
        image: platformBenefitsImage6,
        text: 'Each action, on average, gains coverage from 5 to 20 million unique users of social networks in the world',
    },
]

export const whoIsItems = [
    {
        image: whoIsImage1,
        text: 'Bloggers',
    },
    {
        image: whoIsImage2,
        text: 'Groups',
    },
    {
        image: whoIsImage3,
        text: 'Webmasters',
    },
    {
        image: whoIsImage4,
        text: 'Influencers',
    },
    {
        image: whoIsImage5,
        text: 'Websites',
    },
]

export const cardItems = [
    {
        title: 'Customize your page and subscription levels',
        text: 'Sign up and set up your UpMee profile. Consider subscription levels, from the cheapest to the VIP. Each level offers special benefits to your fans. Think about what you really can give regularly and what your fans will be happy about. Do not complicate!',
        image: howItWorksImage1,
        isReverse: false,
    },
    {
        title: 'Tell your followers that you now have a profile on UpMee',
        text: 'Post in all your social networks to notify your subscribers. UpMee is a place where a special relationship appears between you and your most active fans - those who want something more than just following you on social networks.',
        image: howItWorksImage2,
        isReverse: true,
    },
    {
        title: 'Be active and attentive to your subscribers',
        text: 'Post about updates on the UpMee page on your social networks to attract more subscribers.',
        image: howItWorksImage3,
        isReverse: false,
    },
]

export const talentItems = [
    {
        image: talentImage1,
        text: 'Subscription levels',
    },
    {
        image: talentImage2,
        text: 'Streams',
    },
    {
        image: talentImage3,
        text: 'Donations',
    },
    {
        image: talentImage4,
        text: 'Access to private chats',
    },
    {
        image: talentImage5,
        text: 'Blog stats',
    },
    {
        image: talentImage6,
        text: 'Private posts',
    },
]

import { Container, Input, LinkButton } from '../../components'

import styles from './styles.module.css'

const SignInPage = () => {
    return (
        <Container>
            <div className={styles.wrapper}>
                <div>
                    <h2 className={styles.title}>
                        Login to your personal account
                    </h2>
                    <p className={styles.text}>
                        <span className={styles.textPurple}>UpMee - </span>
                        international advertising exchange, we monetize
                        creativity and make advertising safer together.
                    </p>
                </div>
                <div>
                    <div className={styles.row}>
                        <LinkButton
                            text="Sign In"
                            to="/sign_in"
                            className="font-normal max-w-[35%] lg:max-w-full bg-pink rounded-20"
                        />
                        <LinkButton
                            text="Sign Up"
                            to="/sign_up"
                            className="font-normal bg-purple lg:max-w-full rounded-20"
                        />
                    </div>
                    <div className={styles.inputs}>
                        <Input placeholder="E-mail" />
                        <Input placeholder="Password" type="password" />
                    </div>
                    <LinkButton
                        to="/sign_in"
                        text="Enter"
                        className="font-normal mb-4 bg-purple max-w-full rounded-20"
                    />
                    <div className={styles.forgot}>Forgot your password?</div>
                </div>
            </div>
        </Container>
    )
}

export default SignInPage

import { Title } from '../../components'
import { whyUsItems } from '../../constants'
import styles from './styles.module.css'

const WhyUsSection = () => {
    return (
        <div className={styles.wrapper}>
            <Title
                text="Why us?"
                className="text-purple"
                bigTitle
                titleMargin
            />
            <div className={styles.items}>
                {whyUsItems.map((item, index) => (
                    <div className={styles.item} key={index}>
                        <div className={styles.imageContainer}>
                            <img
                                className={styles.image}
                                src={item.image}
                                alt="Image"
                            />
                        </div>
                        <div className={styles.text}>{item.text}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default WhyUsSection

import styles from './styles.module.css'

const Step = ({ src, text, num }) => {
    return (
        <div className={styles.step}>
            <div className={styles.imageContainer}>
                <img src={src} alt="image" />
            </div>
            <h6 className={styles.title}>Step {num}</h6>
            <p className={styles.text}>{text}</p>
        </div>
    )
}

export default Step

import { Card, Title } from '../../components'
import { cardItems } from '../../constants'
import styles from './styles.module.css'

const HowItWorksTipsSection = () => {
    return (
        <div className={styles.wrapper}>
            <Title
                text="How it works?"
                className="text-purple"
                bigTitle
                titleMargin
            />
            <div className={styles.cards}>
                {cardItems.map((item, index) => (
                    <Card key={index} item={item} index={index} />
                ))}
            </div>
        </div>
    )
}

export default HowItWorksTipsSection

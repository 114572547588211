import { useState } from 'react'
import { Link } from 'react-router-dom'
import { File, Input, Textarea, Title } from '../../components'
import styles from './styles.module.css'

const ProjectPublicationSection = () => {
    const [charactersCount, setCharactersCount] = useState(1000)

    const onChangeTextareaText = (text) => {
        if (!text.length) setCharactersCount(() => 1000)
        setCharactersCount(() => 1000 - text.length)
    }

    return (
        <div className={styles.wrapper}>
            <Title
                text="Project publication"
                className="text-center"
                titleMargin
            />
            <div className={styles.inputLabel}>
                <label className={styles.label} htmlFor="projectName">
                    Project name
                </label>
                <Input
                    id="projectName"
                    placeholder="Enter project name"
                    className={`${styles.input} rounded-12`}
                />
            </div>
            <div className={styles.descriptionContainer}>
                <label
                    className={styles.descriptionLabel}
                    htmlFor="projectDescription"
                >
                    Project description
                </label>
                <Textarea
                    onChangeTextareaText={onChangeTextareaText}
                    className={styles.input}
                />
            </div>
            <div className={styles.characters}>
                <span className={styles.charactersEmptyBlock} />
                {charactersCount} characters left
            </div>
            <div className={styles.fileContainer}>
                <div className={styles.fileEmptyBlock}></div>
                <File className={styles.file} />
            </div>
            <div className={styles.inputLabel}>
                <label className={styles.label} htmlFor="dt">
                    Relevant to
                </label>
                <input className="hidden" id="dt" type="date" />
                <div className={styles.date}>27/04/2023</div>
            </div>
            <div className={styles.emailContainer}>
                <label className={styles.label} htmlFor="email">
                    E-mail
                </label>
                <Input
                    id="email"
                    placeholder="Enter e-mail"
                    className={`${styles.input} rounded-12`}
                />
            </div>
            <div className={styles.inputLabel}>
                <span />
                <p className={styles.budgetText}>
                    Only to activate the profile, we send a free SMS to it with
                    the registration code. If you are already registered,{' '}
                    <Link className={styles.budgetLink} to="/sign_in">
                        Log in
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default ProjectPublicationSection

import { useEffect, useState } from 'react'
import styles from './styles.module.css'

const Textarea = ({ onChangeTextareaText, className }) => {
    const [text, setText] = useState('')

    useEffect(() => {
        onChangeTextareaText(text)
    }, [onChangeTextareaText, text])

    return (
        <textarea
            className={`${styles.textarea} ${className}`}
            value={text}
            rows={9}
            id="projectDescription"
            placeholder="Enter project description"
            maxLength={1000}
            onChange={(e) => setText(e.target.value)}
        />
    )
}

export default Textarea

import { Link } from 'react-router-dom'
import styles from './styles.module.css'

const LinkButton = ({ text, color, height = 'h-16', to, className }) => {
    return (
        <Link
            to={to}
            className={`${styles.link} ${color} ${height} ${className}`}
        >
            {text}
        </Link>
    )
}

export default LinkButton

import arrowPrev from '../../assets/arrow_prev.svg'
import arrowNext from '../../assets/arrow_next.svg'

// eslint-disable-next-line no-unused-vars
const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
    <img {...props} src={arrowPrev} alt="arrow" />
)
// eslint-disable-next-line no-unused-vars
const NextArrow = ({ currentSlide, slideCount, ...props }) => (
    <img {...props} src={arrowNext} alt="arrow" />
)

export { PrevArrow, NextArrow }

import { useEffect } from 'react'

const ImageLoader = ({ images }) => {
    useEffect(() => {
        const loadImage = (src) => {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.onload = () => resolve()
                img.onerror = () => reject()
                img.src = src
            })
        }

        const loadImages = async () => {
            try {
                await Promise.all(images.map((imageUrl) => loadImage(imageUrl)))
            } catch (error) {
                console.error('Error loading images.')
            }
        }

        loadImages()
    }, [images])

    return null
}

export default ImageLoader

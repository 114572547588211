import { reachItems } from '../../constants'
import styles from './styles.module.css'

const Reach = () => {
    return (
        <div className={styles.wrapper}>
            {reachItems.map((item, index) => (
                <div
                    className={`${styles.column} ${
                        index < reachItems.length - 1 ? styles.border : ''
                    }`}
                    key={index}
                >
                    <div className={styles.value}>{item.value}</div>
                    <div className={styles.text}>{item.text}</div>
                </div>
            ))}
        </div>
    )
}

export default Reach

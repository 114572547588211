import styles from './styles.module.css'

const Title = ({ text, className, bigTitle, titleMargin }) => {
    return (
        <h2
            className={`${
                bigTitle ? styles.bigTitle : styles.title
            } ${className} ${titleMargin ? styles.titleMargin : ''}`}
        >
            {text}
        </h2>
    )
}

export default Title

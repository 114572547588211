import { useState } from 'react'
import { BloggerCard, Pagination } from '../../components'
import { bloggers } from '../../constants/bloggers'
import styles from './styles.module.css'

const SearchResultSection = () => {
    const [isSorted, setIsSorted] = useState(false)
    const [activePage, setActivePage] = useState(0)

    const itemsPerPage = 5
    const paginatedBloggers = []

    for (let i = 0; i < bloggers.length; i += itemsPerPage) {
        const page = bloggers.slice(i, i + itemsPerPage)
        paginatedBloggers.push(page)
    }

    const currentBloggers = paginatedBloggers[activePage]

    const changeActivePage = (page) => {
        setActivePage(page)
    }

    return (
        <div className="mb-20">
            <div className={styles.wrapper}>
                <h3 className={styles.title}>Searching results</h3>
                <span
                    className={`${styles.sortBy} ${
                        isSorted ? styles.rotated : ''
                    }`}
                    onClick={() => setIsSorted(!isSorted)}
                >
                    Sort by
                </span>
            </div>
            <ul className={styles.cardsList}>
                {currentBloggers.map((blogger, index) => (
                    <BloggerCard key={index} blogger={blogger} />
                ))}
            </ul>
            <Pagination
                pages={Math.ceil(bloggers.length / itemsPerPage)}
                activePage={activePage}
                changeActivePage={changeActivePage}
            />
        </div>
    )
}

export default SearchResultSection

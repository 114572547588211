export const socialOptions = [
    { value: 'youtube', label: 'YOUTUBE' },
    { value: 'tiktok', label: 'TIKTOK' },
    { value: 'instagram', label: 'INSTAGRAM' },
    { value: 'telegram', label: 'TELEGRAM' },
    { value: 'twitter', label: 'TWITTER' },
    { value: 'snapchat', label: 'SNAPCHAT' },
    { value: 'twitch', label: 'TWITCH' },
    { value: 'wechat', label: 'WECHAT' },
    { value: 'sina', label: 'SINA' },
    { value: 'webo', label: 'WEBO' },
    { value: 'qq', label: 'QQ' },
    { value: 'kuaishou', label: 'KUAISHOU' },
    { value: 'qzone', label: 'QZONE' },
    { value: 'quora', label: 'QUORA' },
    { value: 'discord', label: 'DISCORD' },
    { value: 'mastodon', label: 'MASTODON' },
]

export const categoriesOptions = [
    { value: 'transport', label: 'Transport' },
    { value: 'fashion and beauty', label: 'Fashion and beauty' },
    { value: 'humor', label: 'Humor' },
    { value: 'education', label: 'Education' },
    { value: 'entertainment', label: 'Entertainment' },
    {
        value: 'channels for the whole family',
        label: 'Channels for the whole family',
    },
    { value: 'film and animation', label: 'Film and animation' },
    { value: 'cooking', label: 'Cooking' },
    { value: 'games', label: 'Games' },
    { value: 'hobby and style', label: 'Hobby and style' },
    { value: 'music', label: 'Music' },
    { value: 'news and politics', label: 'News and politics' },
    {
        value: 'public activity and non-profit organizations',
        label: 'Public activity and non-profit organizations',
    },
    { value: 'people and blogs', label: 'People and blogs' },
    { value: 'animals', label: 'Animals' },
    { value: 'science and technology', label: 'Science and technology' },
    { value: 'sport', label: 'Sport' },
    { value: 'trips', label: 'Trips' },
]

export const countriesOptions = [
    { value: 'afghanistan', label: 'Afghanistan' },
    { value: 'albania', label: 'Albania' },
    { value: 'algeria', label: 'Algeria' },
    { value: 'andorra', label: 'Andorra' },
    { value: 'angola', label: 'Angola' },
    { value: 'antigua and Barbuda', label: 'Antigua and Barbuda' },
    { value: 'argentina', label: 'Argentina' },
    { value: 'armenia', label: 'Armenia' },
    { value: 'australia', label: 'Australia' },
    { value: 'austria', label: 'Austria' },
    { value: 'azerbaijan', label: 'Azerbaijan' },
    { value: 'the bahamas', label: 'The Bahamas' },
    { value: 'bahrain', label: 'Bahrain' },
    { value: 'bangladesh', label: 'Bangladesh' },
    { value: 'barbados', label: 'Barbados' },
    { value: 'belarus', label: 'Belarus' },
    { value: 'belgium', label: 'Belgium' },
    { value: 'belize', label: 'Belize' },
    { value: 'benin', label: 'Benin' },
    { value: 'bhutan', label: 'Bhutan' },
    { value: 'bolivia', label: 'Bolivia' },
    { value: 'bosnia and herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'botswana', label: 'Botswana' },
    { value: 'brazil', label: 'Brazil' },
    { value: 'brunei', label: 'Brunei' },
    { value: 'bulgaria', label: 'Bulgaria' },
    { value: 'burkina faso', label: 'Burkina Faso' },
    { value: 'burundi', label: 'Burundi' },
    { value: 'cabo verde', label: 'Cabo Verde' },
    { value: 'cambodia', label: 'Cambodia' },
    { value: 'cameroon', label: 'Cameroon' },
    { value: 'canada', label: 'Canada' },
    { value: 'central african republic', label: 'Central African Republic' },
    { value: 'chad', label: 'Chad' },
    { value: 'chile', label: 'Chile' },
    { value: 'china', label: 'China' },
    { value: 'colombia', label: 'Colombia' },
    { value: 'comoros', label: 'Comoros' },
    {
        value: 'congo, democratic republic of the',
        label: 'Congo, Democratic Republic of the',
    },
    { value: 'congo, republic of the', label: 'Congo, Republic of the' },
    { value: 'costa rica', label: 'Costa Rica' },
    { value: 'côte d’ivoire', label: 'Côte d’Ivoire' },
    { value: 'croatia', label: 'Croatia' },
    { value: 'cuba', label: 'Cuba' },
    { value: 'cyprus', label: 'Cyprus' },
    { value: 'czech republic', label: 'Czech Republic' },
    { value: 'denmark', label: 'Denmark' },
    { value: 'djibouti', label: 'Djibouti' },
    { value: 'dominica', label: 'Dominica' },
    { value: 'dominican republic', label: 'Dominican Republic' },
    { value: 'east timor (timor-leste)', label: 'East Timor (Timor-Leste)' },
    { value: 'ecuador', label: 'Ecuador' },
    { value: 'egypt', label: 'Egypt' },
    { value: 'el salvador', label: 'El Salvador' },
    { value: 'equatorial guinea', label: 'Equatorial Guinea' },
    { value: 'eritrea', label: 'Eritrea' },
    { value: 'estonia', label: 'Estonia' },
    { value: 'eswatini', label: 'Eswatini' },
    { value: 'ethiopia', label: 'Ethiopia' },
    { value: 'fiji', label: 'Fiji' },
    { value: 'finland', label: 'Finland' },
    { value: 'france', label: 'France' },
    { value: 'gabon', label: 'Gabon' },
    { value: 'the gambia', label: 'The Gambia' },
    { value: 'georgia', label: 'Georgia' },
    { value: 'germany', label: 'Germany' },
    { value: 'ghana', label: 'Ghana' },
    { value: 'greece', label: 'Greece' },
    { value: 'grenada', label: 'Grenada' },
    { value: 'guatemala', label: 'Guatemala' },
    { value: 'guinea', label: 'Guinea' },
    { value: 'guinea-bissau', label: 'Guinea-Bissau' },
    { value: 'guyana', label: 'Guyana' },
    { value: 'haiti', label: 'Haiti' },
    { value: 'honduras', label: 'Honduras' },
    { value: 'hungary', label: 'Hungary' },
    { value: 'iceland', label: 'Iceland' },
    { value: 'india', label: 'India' },
    { value: 'indonesia', label: 'Indonesia' },
    { value: 'iran', label: 'Iran' },
    { value: 'iraq', label: 'Iraq' },
    { value: 'ireland', label: 'Ireland' },
    { value: 'israel', label: 'Israel' },
    { value: 'italy', label: 'Italy' },
    { value: 'jamaica', label: 'Jamaica' },
    { value: 'japan', label: 'Japan' },
    { value: 'jordan', label: 'Jordan' },
    { value: 'kazakhstan', label: 'Kazakhstan' },
    { value: 'kenya', label: 'Kenya' },
    { value: 'kiribati', label: 'Kiribati' },
    { value: 'korea, north', label: 'Korea, North' },
    { value: 'korea, south', label: 'Korea, South' },
    { value: 'kosovo', label: 'Kosovo' },
    { value: 'kuwait', label: 'Kuwait' },
    { value: 'kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'laos', label: 'Laos' },
    { value: 'latvia', label: 'Latvia' },
    { value: 'lebanon', label: 'Lebanon' },
    { value: 'lesotho', label: 'Lesotho' },
    { value: 'liberia', label: 'Liberia' },
    { value: 'libya', label: 'Libya' },
    { value: 'liechtenstein', label: 'Liechtenstein' },
    { value: 'lithuania', label: 'Lithuania' },
    { value: 'luxembourg', label: 'Luxembourg' },
    { value: 'madagascar', label: 'Madagascar' },
    { value: 'malawi', label: 'Malawi' },
    { value: 'malaysia', label: 'Malaysia' },
    { value: 'maldives', label: 'Maldives' },
    { value: 'mali', label: 'Mali' },
]

export const currencyOptions = [
    { value: 'usd', label: 'USD' },
    { value: 'uah', label: 'UAH' },
    { value: 'eur', label: 'EUR' },
    { value: 'gbp', label: 'GBP' },
    { value: 'pln', label: 'PLN' },
]

export const subscribersOptions = [
    { value: '1000-5000', label: '1000 - 5000' },
    { value: '5000-1000', label: '5000 - 1000' },
    { value: '10000-25000', label: '10000 - 25000' },
    { value: '25000-50000', label: '25000 - 50000' },
    { value: '50000-100000', label: '50000 - 100000' },
    { value: '100000-250000', label: '100000 - 250000' },
    { value: '250000-500000', label: '250000 - 500000' },
    { value: '500000-1m', label: '500000 - 1m' },
    { value: '1m-3m', label: '1m - 3m' },
    { value: '5m+', label: '5m+' },
]

export const ageOptions = [
    { value: '0-3', label: '0 - 3' },
    { value: '3-6', label: '3 - 6' },
    { value: '7-12', label: '7 - 12' },
    { value: '12-17', label: '12 - 17' },
    { value: '18-24', label: '18 - 24' },
    { value: '25-34', label: '25 - 34' },
    { value: '35-44', label: '35 - 44' },
    { value: '45-54', label: '45 - 54' },
    { value: '55-64', label: '55 - 64' },
    { value: '65+', label: '65+' },
]

export const genderOptions = [
    { value: 'men', label: 'Men' },
    { value: 'women', label: 'Women' },
    { value: 'other', label: 'Other' },
]

export const viewsOptions = [
    { value: '1000-5000', label: '1000 - 5000' },
    { value: '5000-1000', label: '5000 - 1000' },
    { value: '10000-25000', label: '10000 - 25000' },
    { value: '25000-50000', label: '25000 - 50000' },
    { value: '50000-100000', label: '50000 - 100000' },
    { value: '100000-250000', label: '100000 - 250000' },
    { value: '250000-500000', label: '250000 - 500000' },
    { value: '500000-1m', label: '500000 - 1m' },
    { value: '1m-3m', label: '1m - 3m' },
    { value: '5m+', label: '5m+' },
]

export const advertisementOptions = [
    { value: 'pre-roll', label: 'Pre-roll' },
    { value: 'mid-roll', label: 'Mid-roll' },
    { value: 'post-roll', label: 'Post-roll' },
    { value: 'post', label: 'Post' },
    { value: 'stories', label: 'Stories' },
    { value: 'integration', label: 'Integration' },
    { value: 'banner', label: 'Banner' },
]

export const typeOptions = [
    { value: 'bloggers', label: 'Bloggers' },
    { value: 'channels', label: 'Channels' },
    { value: 'groups', label: 'Groups' },
    { value: 'blogs', label: 'Blogs' },
    { value: 'sites', label: 'Sites' },
    { value: 'articles', label: 'Articles' },
    { value: 'concerts', label: 'Concerts' },
]

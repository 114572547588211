import styles from './styles.module.css'

const Page404 = () => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.text}>404</h2>
        </div>
    )
}

export default Page404

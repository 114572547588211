import { Container, Input, LinkButton, Checkbox } from '../../components'

import styles from './styles.module.css'

const SignUpPage = () => {
    return (
        <Container>
            <div className={styles.wrapper}>
                <div>
                    <h2 className={styles.title}>Registration</h2>
                    <h4 className={styles.subtitle}>Who are you?</h4>
                    <div className={styles.checkboxes}>
                        <Checkbox
                            className={styles.checkbox}
                            text="Influencer"
                        />
                        <Checkbox
                            className={styles.checkbox}
                            text="Advertiser"
                        />
                    </div>
                    <p className={styles.text}>
                        <span className={styles.textPurple}>UpMee - </span>
                        international advertising exchange, we monetize
                        creativity and make advertising safer together.
                    </p>
                </div>
                <div>
                    <div className={styles.row}>
                        <LinkButton
                            text="Sign In"
                            to="/sign_in"
                            className="font-normal max-w-[35%] lg:max-w-full bg-purple rounded-20"
                        />
                        <LinkButton
                            text="Sign Up"
                            to="/sign_up"
                            className="font-normal bg-pink lg:max-w-full rounded-20"
                        />
                    </div>
                    <div className={styles.inputs}>
                        <Input placeholder="E-mail" />
                        <Input placeholder="Password" type="password" />
                        <Input placeholder="Password" type="password" />
                    </div>
                    <LinkButton
                        text="Register"
                        to="/sign_up"
                        className="font-normal bg-purple max-w-full rounded-20"
                    />
                </div>
            </div>
        </Container>
    )
}

export default SignUpPage

import { LinkButton, Title } from '../../components'
import image from '../../assets/work.webp'
import styles from './styles.module.css'

const AttachSection = () => {
    return (
        <div className={styles.wrapper}>
            <div>
                <div className={styles.textColumn}>
                    <Title text="Attract targeted actions" bigTitle />
                    <h4 className={styles.subtitle}>
                        with the help of thousands of bloggers
                    </h4>
                    <p className={styles.text}>
                        Our platform converts the audience of bloggers into
                        buyers of goods and services according to the CPA model
                    </p>
                </div>
                <LinkButton
                    to="/publication"
                    text="Add project"
                    className={styles.button}
                />
            </div>
            <img className={styles.image} src={image} alt="phone" />
        </div>
    )
}

export default AttachSection

import { Container, ImageLoader } from '../../components'
import {
    StartedNow,
    Footer,
    HomeSection,
    SocialMediaLine,
    WhyUsSection,
    HowToUseSection,
} from '../../sections'

import { howToImages } from '../../constants'
import { homeItems } from '../../constants/home'

import HowItWorksTipsSection from '../../sections/HowItWorksTipsSection/HowItWorksTipsSection.jsx'
import EarnMoneySection from '../../sections/EarnMoneySection/EarnMoneySection.jsx'

const TipsPage = () => {
    return (
        <>
            <ImageLoader images={howToImages} />
            <HomeSection item={homeItems.talent} talent />
            <Container>
                <HowItWorksTipsSection />
            </Container>
            <SocialMediaLine />
            <Container>
                <EarnMoneySection />
                <WhyUsSection />
                <HowToUseSection />
            </Container>
            <StartedNow />
            <Footer />
        </>
    )
}

export default TipsPage

import { Title } from '../../components'
import { howItWorksItems } from '../../constants'
import styles from './styles.module.css'

const HowItWorksSection = () => {
    return (
        <div className={styles.wrapper}>
            <Title text="How it works?" bigTitle className="text-purple" />
            <ul>
                {howItWorksItems.map((item, index) => (
                    <li
                        className={`${styles.item} ${
                            index < howItWorksItems.length - 1
                                ? styles.border
                                : ''
                        }`}
                        key={index}
                    >
                        <div className={styles.itemStep}>Step {index + 1}</div>
                        <div className={styles.itemTitle}>{item.title}</div>
                        <div className={styles.itemText}>{item.text}</div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default HowItWorksSection

import { useState } from 'react'
import Select from 'react-select'

const CurrencyInput = ({ options, inputId }) => {
    const [selectedOption, setSelectedOption] = useState(options[0])

    return (
        <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
            }}
            options={options}
            isSearchable={false}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary: '#7000ff',
                },
            })}
            inputId={inputId}
            styles={{
                control: (base) => ({
                    ...base,
                    height: '64px',
                    borderRadius: '12px',
                    border: 'none',
                    width: '100px',
                    textAlign: 'center',
                    fontSize: '24px',
                    backgroundColor: '#f7ecff',
                    cursor: 'pointer',
                }),
                menu: (base) => ({
                    ...base,
                    width: '100px',
                    fontSize: '18px',
                    fontWeight: '300',
                }),
            }}
        />
    )
}

export default CurrencyInput

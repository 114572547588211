import { Container, LinkButton } from '../../components'
import styles from './styles.module.css'

const StartedNow = () => {
    return (
        <Container>
            <div className={styles.container}>
                <div>
                    <h3 className={styles.title}>Get Started Now</h3>
                    <p className={styles.text}>
                        Drive targeted actions with the help of thousands of
                        bloggers
                    </p>
                </div>
                <LinkButton
                    className={`${styles.button} bg-white text-purple`}
                    to="/publication"
                    text="Add project"
                />
            </div>
        </Container>
    )
}

export default StartedNow

import { useId } from 'react'
import styles from './Checkbox.module.css'

const Checkbox = ({ text, className }) => {
    const id = useId()

    return (
        <div className={styles.checkbox}>
            <input type="radio" id={id} name="option" />
            <label className={className} htmlFor={id}>
                {text}
            </label>
        </div>
    )
}

export default Checkbox

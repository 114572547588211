import styles from './styles.module.css'
import { stepImages, stepTexts } from '../../constants'
import Step from './Step/Step.jsx'

const Steps = () => {
    return (
        <div className={styles.steps}>
            {stepImages.map((item, i) => (
                <Step
                    key={i}
                    text={stepTexts[i]}
                    num={i + 1}
                    src={stepImages[i]}
                />
            ))}
        </div>
    )
}

export default Steps

import image1 from '../../assets/faces/face_1.webp'
import image2 from '../../assets/faces/face_2.webp'
import image3 from '../../assets/faces/face_3.webp'

export const persons = [
    {
        name: 'Conscious commercialism',
        subscribers: '44 645',
        image: image1,
    },
    {
        name: 'ABADON',
        subscribers: '44 645',
        image: image2,
    },
    {
        name: 'EeOneGay',
        subscribers: '44 645',
        image: image3,
    },
]

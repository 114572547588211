import { useEffect, useRef } from 'react'
import { socialMediaList } from '../../constants'
import styles from './style.module.css'

const SocialMediaLine = () => {
    const sliderRef = useRef(null)
    const slideSpeed = 1

    useEffect(() => {
        const slider = sliderRef.current

        let scrollLeft = 0
        let animationId = null

        const animateScroll = () => {
            scrollLeft += slideSpeed

            if (scrollLeft >= slider.scrollWidth / 2) {
                scrollLeft = 0
            }

            slider.scrollLeft = scrollLeft

            animationId = requestAnimationFrame(animateScroll)
        }

        animateScroll()

        return () => {
            cancelAnimationFrame(animationId)
        }
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.line} ref={sliderRef}>
                {socialMediaList.map((socialMedia, index) => (
                    <div className={styles.item} key={index}>
                        {socialMedia}
                    </div>
                ))}
                {socialMediaList.map((socialMedia, index) => (
                    <div className={styles.item} key={`copy-${index}`}>
                        {socialMedia}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SocialMediaLine

import { Container } from '../../components'
import {
    BigBannerSection,
    FilterSection,
    Footer,
    SearchSection,
    StartedNow,
    SearchResultSection,
} from '../../sections'

const ExchangePage = () => {
    return (
        <>
            <Container>
                <BigBannerSection />
                <div className="grid grid-cols-[25%_1fr] gap-x-5 lg:flex lg:flex-col">
                    <FilterSection />
                    <div>
                        <SearchSection />
                        <SearchResultSection />
                    </div>
                </div>
            </Container>
            <StartedNow />
            <Footer />
        </>
    )
}

export default ExchangePage

import bloggerImage1 from '../../assets/bloggers/image_1.webp'
import bloggerImage2 from '../../assets/bloggers/image_2.webp'
import bloggerImage3 from '../../assets/bloggers/image_3.webp'
import bloggerImage4 from '../../assets/bloggers/image_4.webp'
import bloggerImage5 from '../../assets/bloggers/image_5.webp'
import bloggerImage6 from '../../assets/bloggers/image_6.webp'
import bloggerImage7 from '../../assets/bloggers/image_7.webp'
import bloggerImage8 from '../../assets/bloggers/image_8.webp'
import bloggerImage9 from '../../assets/bloggers/image_9.webp'
import bloggerImage10 from '../../assets/bloggers/image_10.webp'
import bloggerImage11 from '../../assets/bloggers/image_11.webp'
import bloggerImage12 from '../../assets/bloggers/image_12.webp'
import bloggerImage13 from '../../assets/bloggers/image_13.webp'
import bloggerImage14 from '../../assets/bloggers/image_14.webp'
import bloggerImage15 from '../../assets/bloggers/image_15.webp'
import bloggerImage16 from '../../assets/bloggers/image_16.webp'
import bloggerImage17 from '../../assets/bloggers/image_17.webp'
import bloggerImage18 from '../../assets/bloggers/image_18.webp'
import bloggerImage19 from '../../assets/bloggers/image_19.webp'
import bloggerImage20 from '../../assets/bloggers/image_20.webp'
import bloggerImage21 from '../../assets/bloggers/image_21.webp'
import bloggerImage22 from '../../assets/bloggers/image_22.webp'
import bloggerImage23 from '../../assets/bloggers/image_23.webp'
import bloggerImage24 from '../../assets/bloggers/image_24.webp'
import bloggerImage25 from '../../assets/bloggers/image_25.webp'

export const bloggers = [
    {
        name: 'A_S ARMY',
        uploads: '973',
        subscribers: '7.47M',
        videoViews: '741,800,867',
        image: bloggerImage1,
        links: {
            youtube: 'https://www.youtube.com/channel/UCS5wKBMLOOn0l0wOSnspyZQ',
            facebook: 'https://www.facebook.com/ASgaming.sahilrana',
            twitter: 'https://twitter.com/ytasgaming',
            instagram: 'https://www.instagram.com/sahilrana/',
        },
    },
    {
        name: 'BETER BÖCÜK',
        uploads: '2,427',
        subscribers: '29.3M',
        videoViews: '17,039,863,561',
        image: bloggerImage2,
        links: {
            youtube: 'https://www.youtube.com/channel/UC6gVx_vALsYT-z_u1djJbBQ',
        },
    },
    {
        name: 'Sanyasi Facts',
        uploads: '297',
        subscribers: '6.06M',
        videoViews: '316,612,018',
        image: bloggerImage3,
        links: {
            youtube: 'https://www.youtube.com/channel/UC585fiYPh3TzVHU7LMUGubw',
        },
    },
    {
        name: 'RD facts',
        uploads: '244',
        subscribers: '11M',
        videoViews: '337,962,746',
        image: bloggerImage4,
        links: {
            youtube: 'https://www.youtube.com/channel/UCGp3zjXReUQW9aybsSGE0nA',
        },
    },
    {
        name: 'Factsmind',
        uploads: '141',
        subscribers: '4.94M',
        videoViews: '161,199,192',
        image: bloggerImage5,
        links: {
            youtube: 'https://www.youtube.com/channel/UCF-kFw2NHbxMS61ThJYYCOg',
        },
    },
    {
        name: 'Zhong',
        uploads: '1,441',
        subscribers: '27.8M',
        videoViews: '14,239,325,972',
        image: bloggerImage6,
        links: {
            youtube: 'https://www.youtube.com/channel/UCyagEfIN1okQ-s996XAqCFQ',
            twitter: 'https://twitter.com/zhongig',
            instagram: 'https://www.instagram.com/zhong/',
            tiktok: 'https://www.tiktok.com/@zhong',
        },
    },
    {
        name: 'Mr Facts Hindi',
        uploads: '8',
        subscribers: '4.92M',
        videoViews: '194,611',
        image: bloggerImage7,
        links: {
            youtube: 'https://www.youtube.com/channel/UClmfzjZdA8ChUdsrcP-uhCg',
            instagram: 'https://www.instagram.com/mr_yadavfacts/',
        },
    },
    {
        name: 'Balan Official',
        uploads: '171',
        subscribers: '6.99M',
        videoViews: '13,083,840',
        image: bloggerImage8,
        links: {
            youtube: 'https://www.youtube.com/channel/UCBvTpPaLGyzRRu5DwDqzVvw',
        },
    },
    {
        name: 'RajVideoVision',
        uploads: '15,494',
        subscribers: '2.9M',
        videoViews: '1,436,062,435',
        image: bloggerImage9,
        links: {
            youtube: 'https://www.youtube.com/channel/UCtuUyCytM25kpL4iu5pv8fQ',
        },
    },
    {
        name: 'FOUND FACT',
        uploads: '61',
        subscribers: '8.36M',
        videoViews: '462,793,376',
        image: bloggerImage10,
        links: {
            youtube: 'https://www.youtube.com/channel/UCsviIFlZrf8Ryo4fxjN9nhA',
        },
    },
    {
        name: 'Willie Salim',
        uploads: '592',
        subscribers: '17.1M',
        videoViews: '3,834,659,591',
        image: bloggerImage11,
        links: {
            youtube: 'https://www.youtube.com/channel/UCPCaXSwaos-QI03iZtx8I6g',
        },
    },
    {
        name: 'T-Series',
        uploads: '19,770',
        subscribers: '243M',
        videoViews: '224,962,083,610',
        image: bloggerImage12,
        links: {
            youtube: 'https://www.youtube.com/channel/UCq-Fj5jknLsUf-MWSy4_brA',
            facebook: 'https://www.facebook.com/tseriesmusic',
            twitter: 'https://twitter.com/tseries',
            instagram: 'https://www.instagram.com/tseries.official/',
        },
    },
    {
        name: 'SET India',
        uploads: '113,525',
        subscribers: '157M',
        videoViews: '146,089,050,960',
        image: bloggerImage13,
        links: {
            youtube: 'https://www.youtube.com/channel/UCpEhnqL0y41EpW2TvWAHD7Q',
            facebook: 'https://www.facebook.com/SonyLIV/',
            twitter: 'https://twitter.com/sonyliv',
        },
    },
    {
        name: 'Factsman',
        uploads: '166',
        subscribers: '11M',
        videoViews: '613,500,652',
        image: bloggerImage14,
        links: {
            youtube: 'https://www.youtube.com/channel/UCo5AVKClMMipRBunvArG-Zw',
        },
    },
    {
        name: 'Cocomelon - Nursery Rhymes',
        uploads: '925',
        subscribers: '160M',
        videoViews: '161,326,262,169',
        image: bloggerImage15,
        links: {
            youtube: 'https://www.youtube.com/channel/UCbCmjCuTUZos6Inko4u57UQ',
            facebook: 'https://www.facebook.com/CoComelonKids/',
            twitter: 'https://twitter.com/cocomelonkids',
            instagram: 'https://www.instagram.com/cocomelon_official/',
        },
    },
    {
        name: 'CREATIVE BANDA 1M',
        uploads: '688',
        subscribers: '3.95M',
        videoViews: '118,716,397',
        image: bloggerImage16,
        links: {
            youtube: 'https://www.youtube.com/channel/UC432e8rGKEM5L29kUWMeKyQ',
        },
    },
    {
        name: 'Mast Facts',
        uploads: '18',
        subscribers: '3.38M',
        videoViews: '1,551,247',
        image: bloggerImage17,
        links: {
            youtube: 'https://www.youtube.com/channel/UCgFdlhdVwyeZG-cTedt-ZGA',
        },
    },
    {
        name: 'Vlad and Niki',
        uploads: '560',
        subscribers: '97.6M',
        videoViews: '76,175,757,053',
        image: bloggerImage18,
        links: {
            youtube: 'https://www.youtube.com/channel/UCvlE5gTbOvjiolFlEm-c_Ow',
            instagram: 'https://www.instagram.com/Vlad.super.Vlad/',
        },
    },
    {
        name: 'BLACKPINK',
        uploads: '526',
        subscribers: '88.9M',
        videoViews: '31,501,231,507',
        image: bloggerImage19,
        links: {
            youtube: 'https://www.youtube.com/channel/UCOmHUn--16B90oW2L6FRR3A',
            facebook: 'https://www.facebook.com/BLACKPINKOFFICIAL',
            twitter: 'https://twitter.com/ygofficialblink',
            instagram: 'https://www.instagram.com/blackpinkofficial/',
        },
    },
    {
        name: 'Mks.Vision',
        uploads: '313',
        subscribers: '3.72M',
        videoViews: '1,086,694,285',
        image: bloggerImage20,
        links: {
            youtube: 'https://www.youtube.com/channel/UClJuCv9Cn5uFMOSkSg4w63A',
        },
    },
    {
        name: 'BK EditX',
        uploads: '363',
        subscribers: '2.36M',
        videoViews: '1,017,037,239',
        image: bloggerImage21,
        links: {
            youtube: 'https://www.youtube.com/channel/UCX3mAuINk7o6K8_4NR3Zdcw',
        },
    },
    {
        name: 'Crash Adams',
        uploads: '422',
        subscribers: '4.7M',
        videoViews: '1,598,058,514',
        image: bloggerImage22,
        links: {
            youtube: 'https://www.youtube.com/channel/UCAxCk_CU0lnz6vJyslY9-Tw',
            facebook: 'https://www.facebook.com/crashadamsmusic',
            twitter: 'https://twitter.com/crashadamsmusic',
            instagram: 'https://www.instagram.com/crashadamsmusic/',
            tiktok: 'https://www.tiktok.com/@crashadamsmusic',
        },
    },
    {
        name: 'Drastic Singh',
        uploads: '345',
        subscribers: '3.14M',
        videoViews: '681,245,051',
        image: bloggerImage23,
        links: {
            youtube: 'https://www.youtube.com/channel/UC1uAz7m6GdLDVPZKU4hkfWg',
        },
    },
    {
        name: 'NichLmao',
        uploads: '823',
        subscribers: '24.4M',
        videoViews: '13,292,037,840',
        image: bloggerImage24,
        links: {
            youtube: 'https://www.youtube.com/channel/UC6VAIqNQBc7ggiqvsOHOBqw',
            twitter: 'https://twitter.com/nichlmao',
            instagram: 'https://www.instagram.com/nichlmao/',
            tiktok: 'https://www.tiktok.com/@nichlmao',
        },
    },
    {
        name: 'Amaury Guichon',
        uploads: '219',
        subscribers: '9.29M',
        videoViews: '3,188,296,534',
        image: bloggerImage25,
        links: {
            youtube: 'https://www.youtube.com/channel/UC0fvGpDXi7sV2hbgD-O47yw',
            facebook: 'https://www.facebook.com/amauryguichon.chef',
            instagram: 'https://www.instagram.com/amauryguichon/',
        },
    },
]

import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { persons } from '../../constants/persons'
import { NextArrow, PrevArrow } from '../'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './styles.module.css'

const Carousel = () => {
    const [activeSlide, setActiveSlide] = useState(0)
    const sliderRef = useRef(null)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 3,
        centerPadding: 0,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        beforeChange: (current, next) => setActiveSlide(next),
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    }

    const slideClass = (index) => {
        return index === activeSlide ? 'scale-[110%] lg:scale-100' : ''
    }

    const bgClass = (index) => {
        return index === activeSlide ? 'bg-purple text-white' : ''
    }

    return (
        <Slider ref={sliderRef} {...settings}>
            {persons.concat(persons).map((person, index) => (
                <div
                    className={`${styles.carouselItem} ${slideClass(index)}`}
                    key={index}
                >
                    <div
                        className={`${styles.carouselItemBackground} ${bgClass(
                            index
                        )}`}
                    >
                        <img
                            className={styles.carouselItemImage}
                            src={person.image}
                            alt={person.name}
                        />
                        <h6 className={styles.carouselItemTitle}>
                            {person.name}
                        </h6>
                        <p className={styles.carouselItemSubscribers}>
                            {person.subscribers} subscribers
                        </p>
                    </div>
                    <Link
                        to="/"
                        className={`${styles.carouselItemLink} ${
                            index === activeSlide
                                ? 'text-black'
                                : 'after:content-["▶"] after:text-base after:ml-5'
                        }`}
                    >
                        Go to blog
                    </Link>
                </div>
            ))}
        </Slider>
    )
}

export default Carousel

import { Button } from '../'
import styles from './styles.module.css'

const Pagination = ({ pages, activePage, changeActivePage }) => {
    const numbers = Array.from({ length: pages }, (_, index) => index)

    return (
        <ul className={styles.list}>
            {numbers.map((number, index) => (
                <li key={index} onClick={() => changeActivePage(index)}>
                    <Button
                        className={`${styles.button} ${
                            activePage === index ? styles.active : ''
                        }`}
                        text={number + 1}
                    />
                </li>
            ))}
        </ul>
    )
}

export default Pagination

import {
    Checkbox,
    CurrencyInput,
    Input,
    LinkButton,
    Title,
} from '../../components'
import { currencyOptions } from '../../constants/options'
import styles from './styles.module.css'

const ProjectBudgetSection = () => {
    return (
        <>
            <Title
                className="text-center"
                text="Project budget and payment"
                titleMargin
            />
            <div className={styles.banner}>
                <div className={styles.noItem}></div>
                <p className={styles.bannerText}>
                    Stronons work through a secure transaction-Safe, which
                    provides guarantees to the parties. You will receive a
                    high-quality and timely result or we will refund your money.
                    Service commission 9%
                </p>
            </div>
            <div className={styles.inputLabel}>
                <span className={styles.label}>Payment</span>
                <div className={styles.checkboxes}>
                    <Checkbox
                        className={styles.checkbox}
                        text="I pay on behalf of the company, I need accounting documents"
                    />
                    <Checkbox
                        className={styles.checkbox}
                        text="I pay with a personal card"
                    />
                    <Checkbox
                        className={styles.checkbox}
                        text="I'll make the payment later when I find the artist"
                    />
                </div>
            </div>
            <div className={styles.inputLabel}>
                <label className={styles.budgetLabel} htmlFor="budget">
                    Project budget
                </label>
                <div>
                    <div className={styles.budgetContainer}>
                        <Input
                            className={`${styles.input} rounded-4`}
                            id="budget"
                            type="number"
                            placeholder="Enter budget"
                        />
                        <CurrencyInput
                            options={currencyOptions}
                            inputId="currency"
                        />
                    </div>
                    <p className={styles.budgetText}>
                        Leave the field blank if you cannot estimate the cost of
                        the project.
                    </p>
                </div>
            </div>
            <LinkButton
                to="/publication"
                text="Publish project"
                className={styles.button}
            />
        </>
    )
}

export default ProjectBudgetSection

import { useState } from 'react'
import { howToItems } from '../../constants'
import styles from './styles.module.css'

const HowToSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0)

    return (
        <div className={styles.wrapper}>
            <ul className={styles.list}>
                {howToItems.map((item, index) => (
                    <li
                        className={`${styles.listItem} ${
                            index === currentIndex ? styles.active : ''
                        }`}
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
            <div className={styles.slide}>
                <div className={styles.slideTitle}>
                    You can offer subscribers:
                </div>
                <div className={styles.slideItems}>
                    {howToItems[currentIndex].slide.map((item, index) => (
                        <div className={styles.slideItemContent} key={index}>
                            <div className={styles.imageContainer}>
                                <img src={item.image} alt="Image" />
                            </div>
                            <div className={styles.slideText}>{item.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HowToSlider

import image from '../../assets/big_banner_bg.webp'
import styles from './styles.module.css'

const BigBannerSection = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.textColumn}>
                <h2 className={styles.title}>Do you need advertising?</h2>
                <p className={styles.text}>
                    Hundreds of thousands of bloggers, influencers, webmasters,
                    channels and groups are ready to advertise your business,
                    product or service.
                </p>
            </div>
            <img className={styles.image} src={image} alt="Phone" />
        </div>
    )
}

export default BigBannerSection

import styles from './styles.module.css'

const Card = ({ item, index }) => {
    return (
        <div
            className={`${styles.wrapper}
                ${item.isReverse ? styles.wrapperReverse : ''}`}
        >
            <img className={styles.image} src={item.image} alt="Image" />
            <div>
                <div className={styles.step}>Step {index + 1}</div>
                <h6 className={styles.title}>{item.title}</h6>
                <p className={styles.text}>{item.text}</p>
            </div>
        </div>
    )
}

export default Card

import { HowToSlider, Title } from '../../components'
import styles from './styles.module.css'

const HowToUseSection = () => {
    return (
        <div className={styles.wrapper}>
            <Title
                text="How to use?"
                className="text-purple"
                bigTitle
                titleMargin
            />
            <HowToSlider />
        </div>
    )
}

export default HowToUseSection

import {
    PlatformWork,
    SocialMediaLine,
    StartedNow,
    Footer,
    HomeSection,
} from '../../sections'

import { homeItems, images } from '../../constants/home'
import { ImageLoader } from '../../components'

const HomePage = () => {
    return (
        <>
            <ImageLoader images={images} />
            <HomeSection item={homeItems.upMee} introduction />
            <PlatformWork />
            <HomeSection item={homeItems.bloggers} bloggers />
            <HomeSection item={homeItems.advertisers} advertisers />
            <SocialMediaLine />
            <HomeSection item={homeItems.giveaway} giveaway />
            <HomeSection item={homeItems.tips} tips />
            <StartedNow />
            <Footer />
        </>
    )
}

export default HomePage

import { Input, InputSelect, Title } from '../../components'
import {
    categoriesOptions,
    countriesOptions,
    socialOptions,
} from '../../constants/options'
import styles from './styles.module.css'

const CategoriesAndTagsSection = () => {
    return (
        <div className={styles.wrapper}>
            <Title
                text="Categories and tags"
                className="text-center"
                titleMargin
            />
            <div className={styles.inputLabel}>
                <label className={styles.label} htmlFor="social">
                    Social network
                </label>
                <InputSelect inputId="social" options={socialOptions} />
            </div>
            <div className={styles.inputLabel}>
                <label className={styles.label} htmlFor="categories">
                    Categories
                </label>
                <InputSelect inputId="categories" options={categoriesOptions} />
            </div>
            <div className={styles.inputLabel}>
                <label className={styles.label} htmlFor="quantity">
                    Quantity
                </label>
                <Input
                    className={`${styles.input} rounded-12`}
                    id="quantity"
                    type="number"
                    placeholder="Enter quantity"
                    min={1}
                />
            </div>
            <div className={styles.inputLabel}>
                <label className={styles.label} htmlFor="country">
                    A country
                </label>
                <InputSelect inputId="country" options={countriesOptions} />
            </div>
            <div className={styles.inputLabel}>
                <label className={styles.label} htmlFor="city">
                    City
                </label>
                <Input
                    className={`${styles.input} rounded-12`}
                    id="city"
                    placeholder="Enter city"
                />
            </div>
        </div>
    )
}

export default CategoriesAndTagsSection

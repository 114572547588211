import { talentItems } from '../../constants'
import styles from './styles.module.css'

const TalentItems = () => {
    return (
        <ul className={styles.list}>
            {talentItems.map(({ image, text }, index) => (
                <li className={styles.itemContainer} key={index}>
                    <img className={styles.image} src={image} alt="Image" />
                    <span className={styles.text}>{text}</span>
                </li>
            ))}
        </ul>
    )
}

export default TalentItems

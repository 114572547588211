import styles from './styles.module.css'

const SearchCard = () => {
    return (
        <div className={styles.wrapper}>
            <h6 className={styles.title}>How to search for an influencer?</h6>
            <p className={styles.text}>
                Use our filters to find a suitable artist, offer him a project
                and pay for the service
            </p>
        </div>
    )
}

export default SearchCard

import { Title } from '../../components'
import { platformBenefitsItems } from '../../constants'
import styles from './styles.module.css'

const PlatformBenefitsSection = () => {
    return (
        <div className={styles.wrapper}>
            <Title
                text="Platform benefits"
                className="text-purple"
                bigTitle
                titleMargin
            />
            <ul className={styles.list}>
                {platformBenefitsItems.map((item, index) => (
                    <li className={styles.item} key={index}>
                        <div className={styles.itemContainer}>
                            <div className={styles.imageContainer}>
                                <img
                                    className={styles.image}
                                    src={item.image}
                                    alt="Image"
                                />
                            </div>
                            <p className={styles.text}>{item.text}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default PlatformBenefitsSection

import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Navigation, Logo, Burger } from '../../components'

import styles from './styles.module.css'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleIsOpen = () => {
        if (!isOpen) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
        setIsOpen(!isOpen)
    }

    const closeMenu = () => {
        setIsOpen(false)
        document.body.classList.remove('no-scroll')
    }

    return (
        <header className={styles.header}>
            {isOpen && (
                <div
                    className="w-screen h-full bg-black 2xl:hidden lg:block absolute top-0 left-0 right-0 bottom-0 opacity-40 z-10"
                    onClick={handleIsOpen}
                />
            )}
            <Link to="/">
                <Logo isWhite={false} />
            </Link>
            <Burger isOpen={isOpen} handleIsOpen={handleIsOpen} />
            <Navigation
                closeMenu={closeMenu}
                color="black"
                isOpen={isOpen}
                className={`lg:absolute lg:z-20 lg:opacity-0 lg:h-full lg:bottom-0 lg:-left-full transition-all duration-300 lg:top-0 lg:text-2xl lg:font-semibold lg:bg-white lg:px-8 lg:py-12 shadow-2xl ${
                    isOpen ? 'lg:left-0 lg:opacity-100' : ''
                }`}
                listClassName="lg:flex-col lg:gap-y-2"
            />
            <Link
                to="/sign_in"
                className={`${styles.link} lg:me-16 transition duration-300 hover:bg-purple`}
            >
                Sign In
            </Link>
        </header>
    )
}

export default Header
